import React, { useEffect, useState, Fragment, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useErrorHandler } from 'react-error-boundary';
import { useLocation, useHistory } from 'react-router-dom';
import { Grid, TextField, Typography, useTheme } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { BounceLoader } from 'react-spinners';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import * as ClosingLinesService from 'services/closinglines.service';
import ViewData from './components/ViewData';
import AdvancedSearch from './components/AdvancedSearch';

const useStyles = makeStyles(theme => ({
  container: {},
  buttonContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  pagination: {
    '& .Mui-selected': {
      color: '#fff'
    }
  }
}));

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ClosingLines = () => {
  const classes = useStyles();
  const theme = useTheme();
  const handleError = useErrorHandler();

  let query = useQuery();
  let history = useHistory();

  const [formState, setFormState] = useState({
    values: {
      pageNumber: 1,
      sports: [],
      startDate: moment()
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD'),
      endDate: moment()
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD'),
      missingOnly: true
    }
  });

  const [closingLines, setClosingLines] = useState([]);
  const [changeURL, setChangeURL] = useState(false);

  useEffect(() => {
    setClosingLines(null);
    const timer = setTimeout(() => {
      let pageNumber = 1;

      const _startDate = query.get('startDate');
      const _endDate = query.get('endDate');
      const missingOnly = query.get('missingOnly') === '1' ? true : false;

      let sports = '';
      if (query.get('sports')) {
        sports = query.get('sports');
      }

      if (query.get('pageNumber')) {
        pageNumber = Number(query.get('pageNumber'));
      }

      let sportsArray = [];
      if (sports !== '') {
        sportsArray = sports.split(',');
      }

      setFormState({
        values: {
          pageNumber,
          sports: sportsArray,
          startDate: _startDate,
          endDate: _endDate,
          missingOnly
        }
      });

      ClosingLinesService.getClosingLines(
        pageNumber,
        sports,
        _startDate,
        _endDate,
        missingOnly === true ? 1 : 0
      ).then(result => {
        if (result.status === 200) {
          try {
            const customResult = result.data.map((item, index) => ({
              ...item,
              id: index + 1,
              closing_home: item.closing_home === true ? 0 : item.closing_home,
              closing_away: item.closing_away === true ? 0 : item.closing_away
            }));
            setClosingLines(customResult);
          } catch (err) {
            handleError('ClosingLinesService response error');
          }
        } else {
          handleError('ClosingLinesService api error');
        }
      });
    }, 1000); // 2000ms = 2 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [changeURL]);

  const handleChangeCell = newData => {
    const closingLinesData = {
      eventId: String(newData.event_id),
      shortDescription: newData.short_description,
      closingHome: String(newData.closing_home),
      closingAway: String(newData.closing_away)
    };

    ClosingLinesService.addClosingLines(closingLinesData).then(result => {
      if (result.status === 200) {
        try {
          let updateData = closingLines.map(item => {
            if (
              item.event_id === newData.event_id &&
              item.short_description === newData.short_description
            ) {
              return {
                ...item,
                closing_home: newData.closing_home,
                closing_away: newData.closing_away
              };
            }
            return item;
          });

          setClosingLines(updateData);

          cogoToast.success('Updated successfully!', { position: 'top-right' });
        } catch (err) {
          handleError('ClosingLinesService response error');
        }
      } else {
        cogoToast.error('Updated closing line failed', {
          position: 'top-right'
        });
        handleError('ClosingLinesService api error');
      }
    });
  };

  const handleAdvancedSearch = advancedFormState => {
    let newFormState = {
      ...formState,
      values: {
        ...advancedFormState.values
      }
    };

    let searchURL = `?pageNumber=${
      formState.values.pageNumber
    }&sports=${newFormState.values.sports.toString()}&startDate=${
      newFormState.values.startDate
    }&endDate=${newFormState.values.endDate}&missingOnly=${
      newFormState.values.missingOnly === true ? 1 : 0
    }`;

    history.push({
      pathname: '/user/closinglines',
      search: searchURL
    });
    setChangeURL(!changeURL);
  };

  const handlePageChange = (event, value) => {
    const pageNumber = Number(value);

    let searchURL = `?pageNumber=${pageNumber}&sports=${formState.values.sports.toString()}&startDate=${
      formState.values.startDate
    }&endDate=${formState.values.endDate}&missingOnly=${
      formState.values.missingOnly === true ? 1 : 0
    }`;

    history.push({
      pathname: '/user/closinglines',
      search: searchURL
    });

    setChangeURL(!changeURL);
  };

  return (
    <Fragment>
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={12} lg={12}>
          <div className={classes.buttonContent}>
            <div>
              <Typography variant="h3" className="text-uppercase">
                Closing Lines
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <AdvancedSearch
            onAdvancedSearch={formState => handleAdvancedSearch(formState)}
            state={formState}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          {closingLines ? (
            <ViewData
              data={closingLines}
              onUpdateClosingLinesOnTable={newData => handleChangeCell(newData)}
            />
          ) : (
            <div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: '80px' }}>
                <BounceLoader
                  color={theme.palette.primary.main}
                  loading={true}
                />
              </div>
              <p className="mb-0 pt-3 text-black-50 text-center">Loading...</p>
            </div>
          )}
        </Grid>
        <Grid item xs={12} lg={12} className="d-flex justify-content-end">
          <Pagination
            count={formState.values.pageNumber + 1}
            page={formState.values.pageNumber}
            classes={{ ul: classes.pagination }}
            showFirstButton
            showLastButton
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ClosingLines;
