import { AuthGet, AuthPost } from './request.service';

export const getClosingLines = (
  pageNumber,
  sports,
  startDate,
  endDate,
  missingOnly
) => {
  return AuthGet(
    `/closing-lines?pageNumber=${pageNumber}&sports=${sports}&startDate=${startDate}&endDate=${endDate}&missingOnly=${missingOnly}`
  );
};

export const addClosingLines = data => {
  return AuthPost(`/closing-lines`, data);
};
