import React, { forwardRef, useState, useEffect, useRef } from 'react';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  FileCopyOutlined
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { TextField, TableCell } from '@material-ui/core';
import MaterialTable from 'material-table';
import 'assets/mycustom.scss';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '40px',
    '& .MuiInput-underline:before': {
      borderBottom: 'none' // Remove the bottom border
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none' // Remove the bottom border when focused
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none' // Remove the bottom border on hover
    }
  }
}));

const ViewData = props => {
  const { data, onUpdateClosingLinesOnTable } = props;

  const classes = useStyles();
  const tableRef = useRef();

  const [realData, setRealData] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    setRealData(data);
  }, [data]);

  useEffect(() => {
    if (realData.length > 0) {
      enableEditModeForAllCells();
    }
  }, [realData]);

  // This function enables editing for all cells in the table
  const enableEditModeForAllCells = () => {
    const columns = tableRef.current.dataManager.columns;
    const rows = tableRef.current.dataManager.data;

    // Loop through each row and each column
    rows.forEach((row, rowIndex) => {
      columns.forEach((column, columnIndex) => {
        if (
          column.field === 'closing_home' ||
          column.field === 'closing_away'
        ) {
          tableRef.current.dataManager.startCellEditable(row, column);
        }
      });
    });

    // Update the table to reflect the changes
    tableRef.current.setState({
      ...tableRef.current.state,
      dataManager: tableRef.current.dataManager
    });

    setStatus(true);
  };

  return (
    <div className="generate-table">
      <MaterialTable
        title=""
        isLoading={!status}
        tableRef={tableRef}
        icons={tableIcons}
        columns={[
          {
            title: 'ID',
            field: 'id',
            filtering: false,
            editable: 'never',
            width: 50,
            cellStyle: {
              paddingRight: '5px',
              paddingLeft: '5px',
              paddingTop: '0px',
              paddingBottom: '0px',
              fontSize: '13px'
            }
          },
          {
            title: 'EventId',
            field: 'event_id',
            width: 80,
            cellStyle: {
              paddingTop: '5px',
              paddingBottom: '5px',
              fontSize: '13px'
            },
            editable: 'never',
            hidden: true
          },
          {
            title: 'Date',
            field: 'date',
            width: 80,
            cellStyle: {
              paddingTop: '5px',
              paddingBottom: '5px',
              fontSize: '13px'
            },
            editable: 'never'
          },
          {
            title: 'Event',
            field: 'event_name',
            width: 80,
            cellStyle: {
              paddingTop: '5px',
              paddingBottom: '5px',
              fontSize: '13px'
            },
            editable: 'never'
          },
          {
            title: 'Bet Description',
            field: 'short_description',
            width: 80,
            cellStyle: {
              paddingTop: '5px',
              paddingBottom: '5px',
              fontSize: '13px'
            },
            editable: 'never'
          },
          {
            title: 'Closing For',
            field: 'closing_home',
            width: 40,
            type: 'numeric',
            cellStyle: {
              paddingTop: '5px',
              paddingBottom: '10px',
              fontSize: '13px'
            },
            editable: 'always',
            render: rowData => {
              return (
                <TextField
                  value={rowData.closing_home}
                  variant="standard"
                  className={classes.root}
                />
              );
            }
          },
          {
            title: 'Closing Against',
            field: 'closing_away',
            width: 40,
            type: 'numeric',
            cellStyle: {
              paddingTop: '0px',
              paddingBottom: '0px',
              fontSize: '13px'
            },
            render: rowData => {
              return (
                <TextField
                  value={rowData.closing_away}
                  variant="standard"
                  className={classes.root}
                />
              );
            }
          }
        ]}
        data={realData}
        options={{
          columnsButton: true,
          actionsColumnIndex: -1,
          headerStyle: {
            background: '#f4f5fd',
            color: '#3b3e66',
            borderTop: '2px solid #dcdef1',
            borderBottom: '2px solid #dcdef1'
          },
          exportButton: true,
          pageSize: realData.length === 0 ? 1 : 200,
          padding: 'dense',
          paging: false
        }}
        cellEditable={{
          onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
              // console.log('newValue: ' + newValue);
              console.log('rowData, columnDef', rowData, columnDef);
              let newData;

              if (String(columnDef.field) === 'closing_home') {
                newData = {
                  ...rowData,
                  closing_home: newValue
                };
                onUpdateClosingLinesOnTable(newData);
              }

              if (String(columnDef.field) === 'closing_away') {
                newData = {
                  ...rowData,
                  closing_away: newValue
                };
                onUpdateClosingLinesOnTable(newData);
              }

              setTimeout(resolve, 2000);
            });
          }
        }}
        components={{
          Action: props => <></>
        }}
      />
    </div>
  );
};

export default ViewData;
